import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import Slider from 'react-slick-preact-patch'
import SEO from '../components/seo'

const Heading = styled.h1`
  margin-bottom: 0.25em;
`
const Subheading = styled.p`
  margin-bottom: 2em;
`
const SingleCityServices = styled.div`
  flex: 0 0 25%;
  @media (max-width: 992px) {
    flex: 0 0 50%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
  margin-bottom: 2em;
  a {
    color: #666;
    font-size: 0.9em;
  }
  h3 {
    a {
      color: #333;
    }
    margin-bottom: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 1;
  }
`

const ServicesList = (props) => {
  let { slug, location, stateAbbreviation, locationslug, serviceTitle } = props
  return (
    <li>
      <Link to={'/' + slug + '-' + locationslug + '/'} title={serviceTitle}>
        {serviceTitle}
      </Link>
    </li>
  )
}

const CitiesServicesList = (props) => {
  let { location, slug, stateAbbreviation, services } = props
  return (
    <SingleCityServices>
      <h3 className="text-base">
        {location}, {stateAbbreviation}
      </h3>
      <ul>
        {services.edges.map((item, i) => {
          let cleanReplaceServiceTitle = item.node.serviceTitle.replace(
            /{{city}}/g,
            location
          )
          return (
            <ServicesList
              key={item.node.id}
              locationslug={slug}
              location={location}
              serviceTitle={cleanReplaceServiceTitle}
              slug={item.node.slug}
              stateAbbreviation={stateAbbreviation}
            />
          )
        })}
      </ul>
    </SingleCityServices>
  )
}

const ServiceArea = (props) => (
  <StaticQuery
    query={graphql`
      query getCitiesList {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              location
              slug
              stateAbbreviation
            }
          }
        }
        allContentfulServiceAreaContent {
          edges {
            node {
              id
              slug
              serviceTitle
              content {
                id
                content
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Layout pageProps={props}>
          <SEO
            title={'Our Service Areas | US Quality Construction of Columbus'}
            description={
              'On this page, you will find a list of the areas where our service is available. If your city is not listed, please contact us to confirm if we can provide service in your location.'
            }
          />
          <div className="container brand-padding-y">
            <Heading>Our Service Areas</Heading>
            <Subheading>
            US Quality Construction is proud to offer our services to homeowners in Columbus, OH and the surrounding areas. If your city is not listed below, please do not hesitate to contact us. Here is a list of the cities we serve:
            </Subheading>
            <div className="d-flex flex-wrap">
              {data.allContentfulServiceArea.edges.map((item, i) => {
                return (
                  <CitiesServicesList
                    key={item.node.id}
                    location={item.node.location}
                    stateAbbreviation={item.node.stateAbbreviation}
                    slug={item.node.slug}
                    services={data.allContentfulServiceAreaContent}
                  />
                )
              })}
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default ServiceArea
